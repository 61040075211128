.modal {
    position: fixed;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
    border-radius: 2px;
    border-width: 0;
    padding: 0;
    max-height: 80vh;
    max-width: 80vw;
  
    &[open] {
      animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  
      &.modal--closing {
        animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
      }
    }
  
    &::backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(51, 51, 51, 0.3);
      backdrop-filter: blur(1px);
      animation: none;
    }
  
    & .modal__container {
      box-sizing: border-box;
      min-height: 50px;
      min-width: 50px;
      padding: 1rem;
    }
  }
  
  @keyframes show{
    from {
      opacity: 0;
      transform: translateY(min(100px, 5vh));
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  
  @keyframes hide{
    from {
      opacity: 1;
      transform: translateY(0%);
    }
    to {
      opacity: 0;
      transform: translateY(min(100px, 5vh));
    }
  }