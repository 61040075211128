.linkButton {
  display: inline-block;
  // flex-direction: column;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: #000000;
  color: #FFFFFF;
  height: 48px;
  padding: 0 24px;
  font-size: 12px;
}
.linkButton:hover {
  text-decoration: underline;
}