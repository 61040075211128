.menuContainer {
    position: relative;
    max-width: 380px;
    flex-grow: 1;
}

.menuContainer input {
    box-sizing: border-box;
    width: 100%;
    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    height: 100%;


    /* White/100 */
    background: #FFFFFF;
    /* Warm Gray/100 */
    // border: 1px solid #F2EFED;
    border: none;
    outline: none;
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;


    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #666666;

}

.menu {
    max-height: 240px;
    min-height: 76px;
    width: 381px;
    margin-top: 4px;
    overflow-y: scroll;

    border-radius: 8px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px 16px;
    row-gap: 12px;

    -webkit-box-shadow: 5px 5px 10px #888888;
    box-shadow: 5px 5px 10px #888888;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.menu::-webkit-scrollbar {
    display: none;
  }

.item {
    width: calc(100% - 16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    overflow: hidden;
}
.item:hover {
    background: #CCC !important;
}

.searchButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #666;
}

.searchButton button {
    padding: 0;
    border: none;
    background: #FFFFFF00;
    color: #888;
    transition: color .2s ease-in-out;
}
.searchButton button:hover {
    color: #333;
}