
// Unused Stuff //
.featuredRow {
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    grid-auto-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}
.featuredRow > div {
    overflow: hidden;
    border-radius: 8px;
}
.featuredRow > img {
    width: 100%;
    height: 100%;
}

.gridContainer {
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

// Trending Row Stuff //
.trendingRow {
    // margin-top: 8px;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-auto-rows: 1fr;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
}

.trendingCard {
    padding: 48px;
    border-radius: 8px;
    overflow: hidden;
    background: #FFFFFF;
}
@media (max-width: 575.98px) {
    .trendingCard {
        width: calc(100% - 32px) !important;
        padding: 16px !important;
        flex: 0 0 auto !important;
    }
}

.infoCard {
    background: #000000 !important;
    color: #FFFFFF !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .infoLink {
        color: #FFF;
        font-size: 16px;
        line-height: 150%;
    }
    .infoLink:hover {
        text-decoration: underline;
    }
    .infoText {
        color: #999;
        font-size: 14px;
        letter-spacing: -0.56px;
    }
}
@media (max-width: 991.98px) {
    .infoCard {
        width: calc(100% - 64px);
        flex: 0 0 auto !important;
    }

    .trendingCard {
        padding: 32px;
    }
}

// Collections Grid
.collectionsContainer {
    max-width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    align-items: stretch;
}

.item1 {
    grid-row: 1;
    grid-column: 1;
}
.item2 {
    grid-row: 1;
    grid-column: 2;
}
.item3 {
    grid-row: 1;
    grid-column: 3;
}
@media (max-width: 991.98px) {
    .collectionsContainer {
        grid-template-columns: repeat(3, 1fr);
    }
    .item3 {
        grid-row: 2;
        grid-column: 1;
    }
}
@media (max-width: 575.98px) {
    .collectionsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
    .item2 {
        grid-row: 2;
        grid-column: 1;
    }
    .item3 {
        grid-row: 2;
        grid-column: 2;
    }
}








.overlay {
  position: absolute;
  bottom: 4px;
  // height: calc(100% - 16px);
  color: #fff;
  backdrop-filter: blur(24px) brightness(80%);
  border-radius: 8px;
  margin: 0 4px;
  padding: 4px;
  width: calc(100% - 16px);
  max-width: 350px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4px
}
.overlay > h2 {
  margin-bottom: 0px;
}

@media (min-width: 576px) {
  .overlay {
      justify-content: center;
      margin: 0 8px;
      bottom: 8px;
      padding: 24px;
      // height: calc(112px - 48px);
      width: calc(100% - 16px - 48px);
      h2 {
          margin-bottom: 4px;
      }
  }
}

@media (min-width: 992px) {
  .overlay {
      // height: calc(92px - 48px);
  }
}