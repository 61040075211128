:root {
  --toastify-toast-width: auto !important;
  --architech-gradient: linear-gradient(190deg, #02FFFF66 0%, #0166DC66 60%, #017EBA88 100%);
  --gray-gradient: linear-gradient(190deg, rgb(0,0,0,0.2) 0%, rgb(0,0,0,0.12) 60%, rgb(0,0,0,0.07) 100%);
}

@font-face {
  font-family: 'TWK Everett';
  src: url('twkeverett-regular-webfont.woff2') format('woff2'),
       url('twkeverett-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide';
  src: url('druk-wide-bold-webfont.woff2') format('woff2'),
       url('druk-wide-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

hr {
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Druk Wide';
  text-transform: uppercase;
  /* letter-spacing: -0.04em; */
  font-style: normal;
  margin: 0;
}

h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}

.h25 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

body {
  margin: 0;
  font-family: 'TWK Everett', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(154.88% 98.54% at -0.00% 0%, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #F7F7F7;
  max-width: calc(1440px - 32px);
  margin: auto;
  min-height: 100vh;
  padding: 0 4px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus-visible, textarea:focus-visible {
  outline: 2px solid #0166DCBB !important;
  /* outline: 2px solid #017EBA66; */
}

input:not([type]), input[type=text] {
  height: 48px;
  border: 1px solid #cfccc9;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #666666;
  padding: 0 16px;
  font-family: 'TWK Everett', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
input:not([type]):disabled, input[type=text]:disabled {
  border: 1px solid #cfccc9;
  background: #cfccc9;
}
textarea {
  height: 96px;
  border: 1px solid #cfccc9;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #666666;
  padding: 16px;
}

.card {
  background: #FFFFFF;
  
  border-radius: 8px;
  overflow: hidden;
}

.grayCard {
  border-radius: 8px;
  overflow: hidden;

  background: rgba(255, 255, 255, 0.48);
  backdrop-filter: blur(12px);
}

.imgCard {
  background: rgba(255, 255, 255, 0.48);
}

.wide {
  width: 100% !important;
}

.tall {
  height: 100% !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.pr4 {
  padding-right: 4px !important;
}
.pl4 {
  padding-left: 4px !important;
}

.br8 {
  border-radius: 8px;
  overflow: hidden;
}

.gap8 {
  gap: 8px !important;
}

.gap16 {
  gap: 16px !important;
}

.colGap8 {
  column-gap: 8px !important;
}

.rowGap8 {
  row-gap: 8px !important;
}

.px8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  max-width: 100%;
  margin: 0 auto;
}

.halfMinus8 {
  width: calc(50% - 8px);
}

.imgOverlay {
  width: calc(100% - 16px);
  margin-right: 8px;
  position: absolute;
  left: 8px;
  bottom: 8px;
  backdrop-filter: blur(12px);
  height: calc(98px - 24px);
  
  border-radius: 8px;
}

.genOverlay {
  padding: 8px;
  backdrop-filter: blur(24px) brightness(120%);
  border-radius: 8px;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.square {
  aspect-ratio: 1 / 1;
}

.imgCover,.coverImg {
  object-fit: cover;
  image-position: center;
}

button, #claimButton {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background: #000000;
  color: #FFFFFF;
  height: 48px;
  padding: 0 24px;
  font-family: 'TWK Everett', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
button:disabled, #claimButton:disabled {
  cursor: default !important;
  color: #777777;
}
.clearBtn,.clearButton {
  background: #FFFFFF00;
  color: #000;
}

.tallFill {
  min-height: calc(100vh - 64px - 16px - 8px) !important;
}

.buttonLink {
  border: none;
  background: none;
  padding: none;
  color: black;
  text-decoration: underline;
  height: 1em;
}

.textLink {
  text-decoration: underline;
  transition: color .05s ease;
}
.textLink:hover {
  color: #555;
}

.lightText16 {
  font-size: 16px !important;
  color: #666666 !important;
}

.lightText14 {
  font-family: 'TWK Everett';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  color: #666666 !important;
}

.lightText12 {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #666666;
}
.lightText11 {
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  color: #666666;
}

.lightText10 {
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: #666666;
}

.error {
  border: 2px solid rgba(250, 20, 20, 0.75) !important;
}

.inputAlert {
  text-align: right;
  /* width: 100%; */
  position: relative;
  right: 8px;
  bottom: 36px;
  display: flex;
  justify-content: flex-end;
  height: 1.5em;
}

.fourLineLimit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 4; 
  -webkit-box-orient: vertical;
}

.twoLineLimit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.oneLineLimit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.breakAll {
  word-break: break-all;
}

.noButton {
  background: #FFFFFF00 !important;
  padding: 0 !important;
  border: 0 !important;
  color: black;
}

.unclickable {
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}