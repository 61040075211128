.buttonContainer {
    button {
        color: #222;
        // background: radial-gradient(154.88% 98.54% at -0.00% 0%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.20) 100%), #F7F7F7;
        background: linear-gradient(35deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.10) 100%);
        transition: all 0.2s ease-in-out;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 8px !important;
        text-align: left;
        min-height: 48px;
        height: fit-content !important;
    }
    button:hover {
        // background: linear-gradient(190deg, #02FFFF66 0%, #0166DC66 60%, #017EBA88 100%);
        background: var(--architech-gradient);
        background-color: #EEE;
    }
}