.headerContainer {
    // height: 320px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.tagMargin {
    margin: 0;
}

.tagContainer {
    margin-left: 10vw;
    width: calc(100% - 10vw);
}

@media (min-width: 768px) {
    .tagContainer {
        margin-left: 20vw;
        width: calc(100% - 20vw);
    }
}

@media (min-width: 992px) {
    .headerContainer > div {
        height: 320px;
    }

    .tagMargin {
        margin-bottom: 64px !important;
    }

    .tagContainer {
        margin-left: 0;
        width: calc(50% - 20px);

        p {
            margin-left: 20px;
        }
    }
}

.mainHeader {
    font-size: 32px;
    line-height: 119%;
}

@media (min-width: 768px) {
    .mainHeader {
        font-size: 48px;
    }
}

@media (min-width: 992px) {
    .mainHeader {
        font-size: 54px;
    }
}

.linkRow {
    display: flex;
    flex-wrap: wrap;
    // max-height: 513px;
    column-gap: 8px;
    margin-bottom: 8px;
}

.linkCard {
    position: relative;
    h2 {
        font-size: 14px !important;
    }
}
@media (min-width: 769px) {
    .linkCard {
        aspect-ratio: 1 / 1;
        h2 {
            font-size: 20px;
        }
    }
}

.overlay {
    position: absolute;
    bottom: 4px;
    height: calc(100% - 16px);
    color: #fff;
    backdrop-filter: blur(24px) brightness(80%);
    border-radius: 8px;
    margin: 0 4px;
    padding: 4px;
    width: calc(100% - 16px);
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.overlay > h2 {
    margin-bottom: 0px;
}

@media (min-width: 576px) {
    .overlay {
        justify-content: center;
        margin: 0 8px;
        bottom: 8px;
        padding: 24px;
        height: calc(112px - 48px);
        width: calc(100% - 16px - 48px);
        h2 {
            margin-bottom: 4px;
        }
    }
}

@media (min-width: 992px) {
    .overlay {
        height: calc(92px - 48px);
    }
}

.trendingRow {
    display: flex;
    flex-wrap: wrap;
    // height: 407px;
    gap: 8px;
    margin-bottom: 8px;
}

.trendingCol {
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
}

.trendingInternal {
    margin: 48px;
}

@media (max-width: 575.98px) {
    .trendingInternal {
        margin: 16px !important;
    }
}

.socialRow {
    display: flex;
    flex-wrap: wrap;
    // height: 407px;
    gap: 8px;
    // max-height: 220px;
}
// @media (min-width: 992px) {
//     .socialRow {
//         max-height: 220px;
//     }
// }

.discordCard {
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
    background: #000;
}

.discordCard h2 {
    margin-bottom: 32px;
}

.discordCard div p {
    margin: 0;
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    line-height: 120%;
}

.discordCard div button {
    background: #fff;
    color: #000;
    font-size: 16px;
}