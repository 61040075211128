.statsContainer {
    display: flex;
    width: 100%;
    gap: 32px;
}
@media (max-width: 575.98px) {
    .statsContainer {
        gap: 12px;
    }
}

.vr {
    // height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.number {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
}

.label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #666666;
}

.lightLabel {
  color: #999999;
}