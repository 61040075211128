.fadeImg {
    position: relative; 
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%);
  }
  
//   .fadeImg:after {
//     content:""; 
//     position: absolute; 
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
//     height: 40%;  
//   }