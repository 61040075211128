.menuContainer button {
    width: 100%;
    height: 48px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    border: 1px solid #cfccc9;
    // border: 1px solid red;
    border-radius: 8px;

    background: #FFFFFF;

    font-weight: 400;
    font-size: 14px;
    line-height: 120%;

    color: #666666;
}

.openButton {
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
}

.menu {
    width: 100%;
    // max-height: 128px;
    border-left: 1px solid #cfccc9;
    border-right: 1px solid #cfccc9;
    border-bottom: 1px solid #cfccc9;

    border-radius: 0 0 8px 8px;

    background: #FFFFFF;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px 16px;
}

.item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// .menu form {
//     display: flex;
//     flex-direction: column;
    
//     align-items: space-between;
//     gap: 16px;

//     min-width: 75%;

//     color: #555555;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 120%;
// }