.menu a {
    display: inline-flex;
    // border: 1px solid #cccccc80;
    width: calc(100% - 16px);
    border-radius: 8px;
    height: 32px;
    // justify-content: center;
    align-items: center;
    padding: 8px 8px;
    font-weight: 600;
    color: #222222;
}
.menu a:hover {
    background: #cccccc80;
}

.box {
    -webkit-box-shadow: 0 0 10px #888888;
    box-shadow: 0 0 10px #888888;
}