.trait {
    background: #66666630;
    border-radius: 8px;
    overflow: hidden;
    padding: 6px 16px;
    text-align: center;
    min-width: 48px;

    .type {
        font-size: 14px;
        color: #333333;
        font-weight: 500;
    }

    .value {
        font-size: 12px;
        color: #666666;
    }

    hr {
        margin: 0px
    }
}