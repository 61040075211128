.menu button {
    width: 100%;
    border-radius: 8px;
    height: 32px;
    // justify-content: center;
    align-items: center;
    padding: 8px 8px;
    background: #fff;
    color: #232323
}
.menu button:hover {
    background: #cccccc80;
}

.box {
    -webkit-box-shadow: 0 0 10px #888888;
    box-shadow: 0 0 10px #888888;
}