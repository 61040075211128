.rewardsRow {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 16px 8px;

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important
    }

    p {
        margin-left: 12px;
        font-size: 12px;
        
        span {
            margin-left: -4px;
            font-size: 14px;
            text-decoration: underline;
        }
    }
}