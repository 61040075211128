// .tileContainer a {
//     color: inherit;
//     font-style: inherit;
//     text-decoration: inherit;
// }

.collectionCard {
    position: relative;
    color: white;
    background-color: rgba(255, 255, 255, 0.48);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    max-height: 350px;
    aspect-ratio: 1 / 1;
}

.collectionCard .overlay {
    width: calc(100% - 16px - 24px - 24px);
    margin-left: 8px;
    margin-right: 8px;
    position: absolute;
    bottom: 8px;
    backdrop-filter: blur(12px) brightness(80%);
    height: calc(98px - 24px - 16px);
    padding: 24px;
    
    border-radius: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    // display: flex;
    // align-items: flex-end;
}

.collectionCard > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay > h2 {
    margin: 0 0 10px 0;
}