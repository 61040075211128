.createCard {
    position: relative;
    color: black;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    max-height: 350px;
    aspect-ratio: 1 / 1;
    grid-column: 4;
}

.createCard p {
    color: #999;
    font-size: 14px;
    letter-spacing: -0.56px;
    margin: 8px 0 32px 0;
}
@media (max-width: 991.98px) {
    .createCard {
        grid-column: 3;
    }
}
@media (max-width: 575.98px) {
    .createCard {
        grid-column: 2;
    }
}

.createInner {
    margin: 0 86px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: calc(100% - 16px);
}
@media (max-width: 767.98px) {
    .createInner {
        margin: 0 0 0 8px;
    }

    h2 {
        font-size: 20px !important;
    }

    p {
        font-size: 12px !important;
        margin: 4px 0 !important;
    }
}