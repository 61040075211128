.selectWalletContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .walletTile {
        display: flex;
        flex-direction: column;
        // align-content: stretch;
        align-content: flex-end;
    
        button {
            // flex-grow: 1;
            background: linear-gradient(190deg, rgb(0,0,0,0.1) 0%, rgb(0,0,0,0.1) 100%);
            // border: 1px solid rgb(0,0,0,0.2);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: fit-content;
            padding: 12px;
            gap: 24px;
            color: #333;
            transition: all 0.2s ease-in-out;
            padding-right: 24px;

            position: relative;
            z-index: 1;

            div {
                display: flex;
                align-items: center;
                gap: 24px;
            }
            img {
                height: 64px;
            }
        }
        button:disabled {
            background: rgb(0,0,0,0.5) !important;
            color: #333 !important;
        }
        button:hover {
            color: #111;
            // background: rgb(0,0,0,0.3);
            // background: var(--architech-gradient)
        }
        button::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: var(--architech-gradient);
            transition: opacity 0.1s linear;
            z-index: -1;
            opacity: 0;
            border-radius: 8px;
        }
        button:disabled::before {
            background: rgb(0,0,0,0.0) !important;
        }
        button:hover::before {
            opacity: 1;
        }

        a {
            width: fit-content;
            align-self: flex-end;
            color: #444;
            font-size: 12px;
        }
        a:hover {
            text-decoration: underline;
        }
    }
}   

