.navbar {
    display: flex;
    align-items: center;

    height: 64px;
    margin: 8px 0;
    // padding: 0 8px;
}

.navbarInternal {
    display: flex;
    align-items: center;

    height: calc(100% - 16px);
    width: 100%;
    margin: 8px;
}

.logoLink:hover {
    background-color: initial !important;
}

.navbarInternal a {
    height: 100%;
    color: black;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;

}

.navbarInternal a:hover {
    background-color: #c4c4c4;
}

.activeLink {
    background-color: #c4c4c4;
    cursor: default !important;
}

.navbarInternal form {
    margin-left: auto;
    height: 100%;
}
.navbarInternal button {
// .walletButton {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    height: 100%;
    margin-left: 16px;
}

.walletButton {
    font-size: 16px;
    line-height: 150%;
}

// .burgerMenu {
//     background: #fff;
//     padding: 32px;
//     a {
//         margin-bottom: 24px;
//         font-size: 20px;
//         padding: 8px;
//         border-radius: 8px;
//     }

//     button {
//         background: #FFFFFF00;
//         padding: 0;
//         border: 0;
//         color: #999999;
//     }
// }

.menuNav {
    position: absolute;
    left: 0;
    width: 0;
    overflow: hidden;
    max-width: 80vw;
    z-index: 9;
    font-size: 18px;
    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
    transform: translateX(-80vw);
    transition: transform ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 32px;
    /* transition: width ease 0.2s; */
    a {
      margin-bottom: 24px;
      font-size: 20px;
      padding: 8px;
      border-radius: 8px;
    }

    button {
        background: #FFFFFF00;
        padding: 0;
        border: 0;
        color: #999999;
    }

    .mobileLinks {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        a {
            margin: 0 !important;
            color: #454545;
            // text-decoration: underline;
        }
        a:hover {
            text-decoration: underline;
        }
    }
  }
  .menuNav.showMenu {
    width: 100%;
    transform: translateX(0vw);
  }
