// Main Container Stuff //

// Contains page nav and page
.mainRow {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
@media (min-width: 768px) {
    .mainRow {
        // Height fills page. 64px = navbar; 16px = navbar margin; 8px = bottom margin;
        min-height: calc(100vh - 64px - 16px - 8px) !important;
    }
}

// Navigation Card
.navCard {
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    max-width: 400px;

    .navCardInner {
        margin: 48px;
        display: flex;
        flex-direction: column;

        .navLinks {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 8px;
        }

        h2 {
            margin-bottom: 24px;;
        }

        button {
            border: none;
            height: 48px;
            background: #FFFFFF00;
            border-radius: 8px;
            text-align: left;
            font-size: 16px;
            line-height: 150%;
            padding: 0 16px;
            color: #000000;
            cursor: pointer;
            align-self: flex-start;
        }
        button:disabled {
            background: rgba(0, 0, 0, 0.08) !important;
            cursor: default !important;
            align-self: initial !important;
        }

        .unclickableBtn {
            cursor: default !important;
            background: #FFFFFF00 !important;
        }
    }
}

@media (max-width: 767.98px) {
    .navCard {
        max-width: unset;
        .navCardInner {
            margin: 16px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .navLinks {
                flex-direction: row !important;
                align-items: center;
                margin-left: auto;
            }

            h2 {
                margin-bottom: 0;
                // margin-right: auto;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .navCard {
        .navCardInner {
            
            margin: 24px 24px 8px 24px;
            .navLinks {
                width: 100%;
                justify-content: center;
            }

            h2 {
                margin-bottom: 16px;
                // margin-right: auto;
            }
        }
    }
}




// Stuff for individual pages
.form {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    label {
        color: #666666;
    }
}
.form input[type=text],input:not([type]) {
    width: calc(100% - 32px);
    height: 48px;
    border: 1px solid #cfccc9;
    border-radius: 8px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #666666;
    padding: 0 16px;
}
.form input[type=text]:disabled,input:not([type]):disabled {
    border: 1px solid #cfccc9;
    background: #cfccc9;
}
.form textarea {
    width: calc(100% - 32px);
    height: 96px;
    border: 1px solid #cfccc9;
    border-radius: 8px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #666666;
    padding: 16px;
}

.form > div {
    gap: 16px;
}

.form input[type="file"] {
    display: none;
}

.customfileupload {
    border: 1px solid #cfccc9;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 48px;

    border-radius: 8px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #666666;
}

.pageButton {
    border: none;
    height: 48px;
    background: inherit;
    border-radius: 8px;
    text-align: left;
    font-size: 16px;
    line-height: 150%;
    padding: 0 16px;
    color: #000000;
    cursor: pointer;
    align-self: flex-start;
}

.pageButton:disabled {
    background: rgba(0, 0, 0, 0.08) !important;
    cursor: default !important;
    align-self: initial !important;
}

.percent{
    text-align: right;
    // width: 100%;
    position: relative;
    right: 8px;
    bottom: 32px;
}

.saveToast {
    border-radius: 8px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(12px);
    color: #000;
    width: auto;
    display: flex;
    align-items: center;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 32px
}


.modalLink {
    text-decoration: underline;
}
.modalLink:hover {
    color: #666666;
}

.selectTypeCard {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: 905px) {
      height: calc(100vh - 64px - 16px - 8px);
    }
    @media (max-width: 904.98px) {
      // min-height: calc(100vh - 64px - 16px - 8px);
      height: 768px;
    }
    width: 100%;

    .inner {
        margin: 64px;
        position: relative;
        height: calc(100% - 128px);

        .buttonRow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: flex;
            flex-wrap: wrap;
            gap: 32px;
            justify-content: center;
            align-items: stretch;
            width: 80%;
            height: 256px;
        }

        button {
            padding: 48px 32px;
            background: rgb(0,0,0,0.05);
            color: #000;
            // border: 1px solid rgb(0,0,0,0.05);
            height: 100%;
            // width: 212px;
            display: flex;
            flex-direction: column;
            transition: all 0.1s ease-in-out;
            -webkit-box-shadow: 0 0 5px rgb(0,0,0,0.3);
            box-shadow: 0 0 5px rgb(0,0,0,0.3);
            p {
                flex-grow: 1;
                display: flex;
                align-items: center;
            }
        }
        button:hover {
            background: rgb(0,0,0,0.1);
            p {
                color: #000;
            }
        }
        button:disabled {
          background: rgb(0,0,0,0.2) !important;
          p {
            color: #666;
          }
      }
    }


}



.nftPreviewCard {
    border-radius: 8px;
    background: radial-gradient(280% 98.54% at -0.00% 0%, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%), #F7F7F7;
    padding: 24px;
}




// FROM NFT

.alert {
    text-align: right;
    // width: 100%;
    position: relative;
    right: 8px;
    bottom: 36px;
    display: flex;
    justify-content: flex-end;
    height: 1.5em;
}

.selectAlert {
    text-align: right;
    // width: 100%;
    position: relative;
    right: 32px;
    bottom: 36px;
    display: flex;
    justify-content: flex-end;
    height: 1.5em;
}

.lightButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    
    // width: 48px;
    height: 48px;
    
    /* White/100 */
    
    background: #FFFFFF;
    /* Button */
    
    box-shadow: 3px 9px 32px -4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: 1px solid #DDDDDD;

    font-size: 16px;
    line-height: 150%;
    color: #000000;
}

.error {
    border: 2px solid rgba(250, 20, 20, 0.75) !important;
    border-radius: 8px;
}

.spanButton {
    font-size: 12px;
    margin-top: 4px;
    cursor: pointer;
}

.spanButton:hover {
    text-decoration: underline;
}


input[type="datetime-local"] {
  width: calc(100% - 32px);
  height: 48px;
  border: 1px solid #cfccc9;
  border-radius: 8px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #666666;
  padding: 0 16px;
}
input[type="datetime-local"]:disabled {
  border: 1px solid #cfccc9;
  background: #cfccc9;
}

.reviewBox {
    gap: 8px;
    h6,h5,h4 {
        font-family: 'TWK Everett' !important
    }
}