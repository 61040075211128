.footer {
    width: calc(100% - 63px - 63px);
    border-radius: 8px;
    background: #FFFFFF;
    height: calc(192px - 40px - 40px);
    margin-top: 8px;

    display: flex;
    flex-direction: column;
    padding: 40px 63px;
    margin-bottom: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
}