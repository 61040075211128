.picRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    gap: 8px;
}

@media (min-width: 768px) {
    .picRow {
        max-height: '350px'
    }
}


// NFTs Grid
.nftsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    flex: 1 0;
}


@media (max-width: 991.98px) {
    .nftsContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 575.98px) {
    .nftsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}